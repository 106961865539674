import {
  PROCESSING_STATE,
  SAVE_FAIL_STATE,
  SAVE_SUCCESS_STATE,
  BetaRequestFormState,
  BetaRequestStateTypes,
} from './state';

export const reducer = (
  state: BetaRequestFormState,
  action: BetaRequestStateTypes
): BetaRequestFormState => {
  switch (action.type) {
    case PROCESSING_STATE:
      return { ...state, loading: true };
    case SAVE_FAIL_STATE: {
      state.form.setFields([
        {
          name: 'name',
          errors: action.errors?.name,
        },
        {
          name: 'phone',
          errors: action.errors?.phone,
        },
      ]);

      return {
        ...state,
        isSuccess: false,
        loading: false,
      };
    }
    case SAVE_SUCCESS_STATE: {
      return {
        ...state,
        isSuccess: true,
      };
    }
    default:
      return state;
  }
};
