import 'reflect-metadata';
import { container } from 'tsyringe';

import ApiClient from '../classes/ApiClient';

export default class BetaRequestRepository {
  private apiClient?: ApiClient;

  constructor({ apiClient }: { apiClient?: ApiClient }) {
    this.apiClient = apiClient;
  }

  public async betaRequest({
    name,
    phone,
  }: {
    name: string;
    phone: string;
  }): Promise<object> {
    const path = `/beta-request`;
    const data = await this.apiClient?.post({
      path: path,
      body: JSON.stringify({ name: name, phone: phone }),
      auth: false,
    });
    return data;
  }
}

container.register<BetaRequestRepository>(BetaRequestRepository, {
  useValue: new BetaRequestRepository({
    apiClient: container.resolve(ApiClient),
  }),
});
