interface Props {
  type?: 'small' | 'big';
}

const Logo = ({ type = 'small' }: Props) => {
  const logoSmall = '/images/shophil-logo.png';
  const logoBig = '/images/shophil-white-on-transparent.png';
  let logoSrc = logoSmall;
  let style = {};
  if (type === 'big') {
    logoSrc = logoBig;
    style = {
      width: '260px',
    };
  }
  return (
    <>
      <img src={logoSrc} alt="logo" className="sh-logo" style={style} />
    </>
  );
};

export default Logo;
