import { FormInstance } from 'antd/lib/form';

export interface BetaRequestFormState {
  loading: boolean;
  isSuccess: boolean | null;
  form: FormInstance<any>;
}

export const PROCESSING_STATE = 'PROCESSING';
export const SAVE_FAIL_STATE = 'SAVE_FAIL';
export const SAVE_SUCCESS_STATE = 'SAVE_SUCCESS';

interface ProcessingState {
  type: typeof PROCESSING_STATE;
}

interface SaveFailState {
  type: typeof SAVE_FAIL_STATE;
  errors: Record<string, string[]>;
}

interface SaveSuccessState {
  type: typeof SAVE_SUCCESS_STATE;
}

export type BetaRequestStateTypes =
  | ProcessingState
  | SaveFailState
  | SaveSuccessState;

export function processing(): BetaRequestStateTypes {
  return {
    type: PROCESSING_STATE,
  };
}

export function saveFail({
  erros,
}: {
  erros: Record<string, string[]>;
}): BetaRequestStateTypes {
  return {
    type: SAVE_FAIL_STATE,
    errors: erros,
  };
}

export function saveSuccess(): BetaRequestStateTypes {
  return {
    type: SAVE_SUCCESS_STATE,
  };
}
