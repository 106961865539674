import 'reflect-metadata';
import { container } from 'tsyringe';

import { StoreManager } from './StoreManager';

import appConfig from '../appConfig';

export default class ApiClient {
  private keyStudioToken = 'shophil-studio-token';
  private storage?: StoreManager;

  constructor({ storage }: { storage?: StoreManager }) {
    this.storage = storage;
  }

  public getToken(): string | undefined {
    return this.storage?.get(this.keyStudioToken);
  }

  public getHeaders({ auth }: { auth: boolean }): Record<string, string> {
    const header: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (auth) {
      header['Authorization'] = `Bearer ${this.getToken()}`;
    }

    return header;
  }

  public getHeadersMultiPart({
    auth,
  }: {
    auth: boolean;
  }): Record<string, string> {
    const header: Record<string, string> = {
      Accept: '*/*',
    };

    if (auth) {
      header['Authorization'] = `Bearer ${this.getToken()}`;
    }

    return header;
  }

  private queryParams(
    params?: Record<string, string | string[]> | null
  ): string {
    if (params === undefined || params === null) {
      return '';
    }
    return (
      '?' +
      Object.keys(params)
        .map((k) => {
          if (Array.isArray(params[k])) {
            return (params[k] as string[])
              .map((subK) => {
                return (
                  encodeURIComponent(k) +
                  '[]=' +
                  encodeURIComponent(subK?.toString())
                );
              })
              .join('&');
          }
          return (
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent(params[k]?.toString())
          );
        })
        .join('&')
    );
  }

  public async get({
    path,
    queryParameters,
    auth = true,
    signal,
  }: {
    path: string;
    queryParameters?: Record<string, string | string[]> | null;
    auth?: boolean;
    signal?: AbortSignal;
  }): Promise<any> {
    const result = await fetch(
      `${appConfig.apiEndpoint}${path}${this.queryParams(queryParameters)}`,
      {
        headers: this.getHeaders({ auth: auth }),
        method: 'GET',
        signal: signal,
      }
    );

    if (result.ok) {
      return await result.json();
    }

    return Promise.reject(result);
  }

  public async postData({
    path,
    body,
    queryParameters,
    auth = true,
  }: {
    path: string;
    body: any;
    queryParameters?: Record<string, string>;
    auth?: boolean;
  }): Promise<any> {
    const result = await fetch(
      `${appConfig.apiEndpoint}${path}${this.queryParams(queryParameters)}`,
      {
        headers: this.getHeadersMultiPart({ auth: auth }),
        body: body,
        method: 'POST',
      }
    );

    if (result.ok) {
      return await result.json();
    }

    return Promise.reject(result);
  }

  public async post({
    path,
    body,
    queryParameters,
    auth = true,
  }: {
    path: string;
    body: any;
    queryParameters?: Record<string, string> | null;
    auth?: boolean;
  }): Promise<any> {
    const result = await fetch(
      `${appConfig.apiEndpoint}${path}${this.queryParams(queryParameters)}`,
      {
        headers: this.getHeaders({ auth: auth }),
        body: body,
        method: 'POST',
      }
    );

    if (result.ok) {
      return await result.json();
    }

    return Promise.reject(result);
  }

  public async put({
    path,
    body,
    queryParameters,
    auth = true,
  }: {
    path: string;
    body?: string | null;
    queryParameters?: Record<string, string> | null;
    auth?: boolean;
  }): Promise<any> {
    const result = await fetch(
      `${appConfig.apiEndpoint}${path}${this.queryParams(queryParameters)}`,
      {
        headers: this.getHeaders({ auth: auth }),
        body: body,
        method: 'PUT',
      }
    );

    if (result.ok) {
      return await result.json();
    }

    return Promise.reject(result);
  }

  public async delete({
    path,
    queryParameters,
  }: {
    path: string;
    queryParameters?: Record<string, string> | null;
  }): Promise<any> {
    const result = await fetch(
      `${appConfig.apiEndpoint}${path}${this.queryParams(queryParameters)}`,
      {
        headers: this.getHeaders({ auth: true }),
        method: 'DELETE',
      }
    );
    return await result.json();
  }
}

container.register<ApiClient>(ApiClient, {
  useValue: new ApiClient({ storage: container.resolve(StoreManager) }),
});
