export type Environment = 'develop' | 'staging' | 'production' | 'local';
export interface AppConfig {
  hello: string;
  appVersion: string;
  apiEndpoint: string;
  targetEnvironment: Environment;
  billingHost: string;
  emberHost: string;
  socketHost: string;
}

const appConfig: Readonly<AppConfig> = Object.freeze({
  hello: (process.env.NEXT_PUBLIC_HELLO as string) || '',
  appVersion: (process.env.APP_VERSION as string) || '0.0.0-dev',
  apiEndpoint: (process.env.NEXT_PUBLIC_API_ENDPOINT as string) || '',
  targetEnvironment: process.env.TARGET_ENV as Environment,
  billingHost: (process.env.NEXT_PUBLIC_BILLING_ENDPOINT as string) || '',
  emberHost: (process.env.NEXT_PUBLIC_EMBER as string) || '',
  socketHost: (process.env.NEXT_PUBLIC_SOCKET as string) || '',
});

export default appConfig;
