import 'reflect-metadata';
import store from 'store';
import { container } from 'tsyringe';

export class StoreManager {
  private store: StoreJsAPI;

  constructor() {
    this.store = store;
  }

  public get(key: string): string {
    return this.store.get(key);
  }

  public has(key: string): boolean {
    const value = this.store.get(key);
    return value !== undefined && value !== null && value.length > 0;
  }

  public set(key: string, value: string): void {
    this.store.set(key, value);
  }

  public remove(key: string): void {
    this.store.remove(key);
  }

  public clear(): void {
    this.store.clearAll();
  }
}

container.register<StoreManager>(StoreManager, {
  useValue: new StoreManager(),
});
