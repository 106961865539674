import 'reflect-metadata';
import { container } from 'tsyringe';

import { useCallback, useReducer } from 'react';
import Head from 'next/head';

import Logo from '@/components/Logo';
import CenterContentLayout from '@/components/layouts/shared/CenterContentLayout';
import Footer from '@/components/Footer';

import {
  BetaRequestFormState,
  processing,
  saveFail,
  saveSuccess,
} from '@/common/store/betaRequestForm/state';
import { reducer } from '@/common/store/betaRequestForm/reducer';
import BetaRequestRepository from '@/common/repository/BetaRequestRepository';

import { Card, Row, Col, Form, Input, Button, Typography, Alert } from 'antd';
const { Title, Text } = Typography;

const IndexPage = () => {
  const initialState: BetaRequestFormState = {
    loading: false,
    isSuccess: null,
    form: Form.useForm()[0],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onOK = useCallback(async () => {
    const betaRequestRepository = container.resolve(BetaRequestRepository);
    dispatch(processing());
    try {
      const valuse = state.form.getFieldsValue();
      await betaRequestRepository.betaRequest(valuse);
      dispatch(saveSuccess());
    } catch (error) {
      const errors = await error.json();
      if (errors.status_code == 422) {
        dispatch(saveFail({ erros: errors.errors }));
      }
    }
  }, [state.form]);

  return (
    <>
      <Head>
        <title>shophil</title>
      </Head>
      <CenterContentLayout>
        <Row>
          <Col>
            <Logo type="big" />
            <Title level={2} style={{ color: 'white' }}>
              เลือกช้อปฟีล ลูกค้าช้อปฟิน
            </Title>
            <Title level={4} style={{ color: 'white' }}>
              บริหารร้านค้าโซเชียลอย่างมืออาชีพด้วยระบบที่ช่วยให้คุณโฟกัสธุรกิจหลักได้อย่างเต็มที่
            </Title>
            <Card style={{ marginTop: 30 }}>
              {state.isSuccess !== true && (
                <>
                  <Text>
                    ขณะนี้เราได้เปิดให้บริการโดยจำกัดผู้ใช้เพื่อพัฒนาระบบ
                    <br />
                    คุณสามารถทิ้งเบอร์โทรไว้ให้ทางเราติดต่อกลับได้ตามด้านล่าง
                  </Text>
                </>
              )}
              <Row style={{ marginTop: 20 }}>
                <Col
                  md={{ span: 12, offset: 6 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}>
                  <Form layout="vertical" form={state.form} onFinish={onOK}>
                    {state.isSuccess === true ? (
                      <>
                        <Title level={2}>ทำการลงทะเบียนสำเร็จ</Title>
                        <Title level={4}>ทางเราจะรีบทำการติดต่อกลับไป</Title>
                      </>
                    ) : (
                      <>
                        {state.isSuccess === false && (
                          <Alert
                            message="ไม่สามารถทำการลงทะเบียนได้"
                            type="error"
                            closable
                            style={{ marginBottom: 15 }}
                          />
                        )}
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your name',
                            },
                          ]}>
                          <Input placeholder="ชื่อ" />
                        </Form.Item>
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your phone number',
                            },
                          ]}>
                          <Input placeholder="เบอร์โทร" />
                        </Form.Item>
                        <Button
                          type="primary"
                          className="primary-button"
                          size="large"
                          htmlType="submit"
                          block
                          loading={state.loading}>
                          ลงทะเบียน
                        </Button>
                      </>
                    )}
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Footer />
      </CenterContentLayout>
    </>
  );
};
export default IndexPage;
