import Link from 'next/link';
import { Row, Col } from 'antd';

const Footer = () => {
  return (
    <>
      <div style={{ color: 'white', marginTop: 30 }}>
        <Row gutter={[16, 0]}>
          <Col>Shophil © 2019</Col>
          <Col>
            <Link href="/privacy">
              <a>Privacy Policy</a>
            </Link>
          </Col>
          <Col>
            <Link href="/terms">
              <a>Terms of Service</a>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Footer;
