import { Layout } from 'antd';

const CenterContentLayout = ({ children }: any) => {
  return (
    <>
      <Layout className="content-center">{children}</Layout>
      <style>{`
        .content-center {
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(30deg, #2276ad, #6ec654);
          min-height: 100vh;
          text-align: center;
        }
      `}</style>
    </>
  );
};

export default CenterContentLayout;
